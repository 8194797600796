import Product from './Product';
import { Type } from 'class-transformer';
import ProductSize from './ProductSize';

export default class Item {
    ID!: string;
    @Type(() => Product)
    product!: Product;
    quantity!: number;
    @Type(() => ProductSize)
    size!: ProductSize;
}
