
import OrderStatus, { OrderStatuses } from "@/entity/OrderStatus";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { SlideYUpTransition } from "vue2-transitions";

@Component({ name: "ModalStatusHistory", components: { SlideYUpTransition } })
export default class ModalStatusHistory extends Vue {
  public show = false;
  private hadOpenClass = false;
  public isLoading = true;

  public statusPopover = {
    selected: "",
    comment: "",
    statuses: [],
    visible: false,
    loading: false,
  };

  private orderID = "";

  private rawHistory = new Array<OrderStatus>();
  public get history() {
    return this.rawHistory.map((item) => {
      return {
        ID: item.ID,
        timestamp: item.createdAt.prittify(true),
        status: OrderStatuses.toString(item.status),
        description: item.description,
        type: this.statusToType(item.status),
        icon: this.statusToIcon(item.status),
        read: !item.unread,
      };
    });
  }

  private statusToType(status: OrderStatuses) {
    if (status == OrderStatuses.New) return "primary";
    else if (
      status == OrderStatuses.Approved ||
      status == OrderStatuses.Delivering ||
      status == OrderStatuses.Delivered ||
      status == OrderStatuses.Closed
    )
      return "success";
    else if (status == OrderStatuses.Pending) return "warning";
    else return "danger";
  }

  private statusToIcon(status: OrderStatuses) {
    if (status == OrderStatuses.New) return "fas fa-plus";
    else if (status == OrderStatuses.Approved) return "fas fa-check";
    else if (
      status == OrderStatuses.Delivering ||
      status == OrderStatuses.Delivered
    )
      return "fas fa-truck";
    else if (status == OrderStatuses.Pending) return "fas fa-hourglass-start";
    else if (status == OrderStatuses.Closed) return "fas fa-times";
    else return "fas fa-exclamation";
  }

  public async open(orderID: string) {
    Object.assign(this, this.initData());

    this.show = true;

    this.orderID = orderID;
    this.rawHistory = await OrderStatus.getMany(
      `orders/${orderID}/statushistory`
    );

    this.statusPopover.statuses = OrderStatus.getArrayObject(
      "value",
      "label",
      false
    );
    this.statusPopover.statuses.splice(0, 1);

    this.disableSelected();

    this.isLoading = false;
  }

  public async newStatus() {
    this.statusPopover.loading = true;

    const newStatus = new OrderStatus();
    newStatus.status = parseInt(this.statusPopover.selected);
    if (this.statusPopover.comment)
      newStatus.description = this.statusPopover.comment;

    const res = await newStatus.postOne(`orders/${this.orderID}/statushistory`);
    this.rawHistory.push(res);

    this.disableSelected();

    this.$emit("orderStatusChanged", res);

    this.statusPopover.selected = "";
    this.statusPopover.comment = "";

    this.statusPopover.loading = false;
    this.$message({
      showClose: true,
      message: "Stato dell'ordine modificato",
      type: "success",
    });
  }

  private disableSelected() {
    this.statusPopover.statuses.forEach((item) => {
      if (item.value == this.rawHistory[this.rawHistory.length - 1].status)
        item.disabled = true;
      else item.disabled = false;
    });
  }

  @Watch("show")
  private showChanged(val) {
    const documentClasses = document.body.classList;

    if (val) this.hadOpenClass = documentClasses.contains("modal-open");

    if (val && !this.hadOpenClass) {
      documentClasses.add("modal-open");
    } else if (!val && !this.hadOpenClass) {
      documentClasses.remove("modal-open");
    }
  }

  private initData() {
    return {
      isLoading: true,
      statusPopover: {
        selected: "",
        comment: "",
        statuses: [],
        visible: false,
        loading: false,
      },
      orderID: "",
      rawHistory: new Array<OrderStatus>(),
    };
  }
}
