
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Order from "./entity/Order";
import ModalOrdine from "@/views/Modal/ModalOrdine";
import { RuntimeEventSubscriber } from "./util/Helper";
import Session from "./Session";

@Component({
  components: {
    ModalOrdine: require("src/views/Modal/ModalOrdine.vue").default,
  },
})
export default class App extends Vue {
  $refs: {
    modalOrdine: ModalOrdine;
  };

  private async openOrdineModal(orderID: string) {
    this.$refs.modalOrdine.open(await Order.getOne(`orders/${orderID}`));
  }

  private registerForNotifications() {
    if (Session.instance.userBusiness == null) {
      Session.instance.onLogin.once(this.registerForNotifications);
      return;
    }

    if (!Session.instance.userBusiness.permission.canReceiveNotifications) {
      return;
    }

    const newOrderNotifications = new Array();

    Session.instance.onNewOrder.on(async (notificationOrder) => {
      try {
        await new Audio(
          require("../public/static/sounds/notification.mp3")
        ).play();
      } catch { }

      const notification = this.$notify({
        customClass: "easyalert info clickable",
        title: "Nuovo ordine",
        position: "top-right",
        duration: 10000,
        iconClass: "fa fa-archive",
        message:
          "È arrivato un nuovo ordine. Clicca su questa notifica per visualizzarlo.",
        showClose: true,
        onClick: async () => {
          Session.instance.socket.emit("newOrderClicked", notificationOrder.ID);

          await this.openOrdineModal(notificationOrder.ID);
        },
      });

      newOrderNotifications.push({
        notification: notification,
        orderID: notificationOrder.ID,
      });
    });

    RuntimeEventSubscriber.add("onOrderRead", (data) => {
      newOrderNotifications.forEach((item) => {
        if (item.orderID === data) {
          item.notification.close();

          newOrderNotifications.delete(item);
        }
      });
    });
  }

  mounted() {
    this.registerForNotifications();
  }
}
