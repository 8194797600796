
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Navbar, NavbarToggleButton } from "@/components/UIComponents";
import Order from "@/entity/Order";
import Session from "@/Session";
import ModalOrdine from "@/views/Modal/ModalOrdine";
import { RuntimeEventSubscriber } from "@/util/Helper";
import { OrderNotification } from "@/entity/Store";
import EditCurrentUser from "@/views/Modal/EditCurrentUser/EditCurrentUser";

@Component({
  components: {
    Navbar,
    NavbarToggleButton,
    ModalOrdine: require("@/views/Modal/ModalOrdine.vue").default,
    EditCurrentUser: require("@/views/Modal/EditCurrentUser/EditCurrentUser.vue").default,
  },
})
export default class TopNavbar extends Vue {
  $refs: {
    modalOrdine: ModalOrdine;
    editCurrentUser: EditCurrentUser;
  };

  private activeNotifications = false;
  public showNavbar = false;

  public canShowNotifications = false;
  public notificationsCount = 0;
  public notifications = new Array<OrderNotification>();

  public async btnClicked(orderNotification: OrderNotification) {
    this.$refs.modalOrdine.open(
      await Order.getOne(`orders/${orderNotification.ID}`)
    );

    Session.instance.socket.emit("newOrderClicked", orderNotification.ID);
  }

  public logout() {
    try {
      Session.instance.logout();
    } catch { }
  }

  public orderStatusChanged(order: Order) {
    RuntimeEventSubscriber.emit("orderStatusChanged", order.ID);
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public toggleNotificationDropDown() {
    this.activeNotifications = !this.activeNotifications;
  }

  public closeDropDown() {
    this.activeNotifications = false;
  }

  public toggleSidebar() {
    (<any>this).$sidebar.displaySidebar(!(<any>this).$sidebar.showSidebar);
  }

  public hideSidebar() {
    (<any>this).$sidebar.displaySidebar(false);
  }

  public minimizeSidebar() {
    (<any>this).$sidebar.toggleMinimize();
  }

  public toggleNavbar() {
    this.showNavbar = !this.showNavbar;
  }

  private notificationEvent() {
    this.notificationsCount = Session.instance.orderNotifications.length;
    this.notifications = Session.instance.orderNotifications;
  }

  public editUser() {
    this.$refs.editCurrentUser.open();
  }

  mounted() {
    if (Session.instance.userBusiness.permission.canReceiveNotifications) {
      this.canShowNotifications = true;
      this.notificationEvent();

      Session.instance.onNewOrder.on(this.notificationEvent);
      RuntimeEventSubscriber.add("onOrderRead", this.notificationEvent);
    }
  }

  beforeDestroy() {
    RuntimeEventSubscriber.remove("onNewOrder", this.notificationEvent);
    RuntimeEventSubscriber.remove("onOrderRead", this.notificationEvent);
  }
}
